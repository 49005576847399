import {
  GetOverviewQueryParams,
  OverviewResults,
} from '@/models/reports/overview';
import { stringifyUrlQuery } from '@/utils/util';
import httpClient from '../http-client';

export const OVERVIEW_ENDPOINT = 'reports/overview';

/**
 * Gets an overview report that summarizes the state of devices in an organization.
 * Includes:
 *  - devices overview
 *  - history of patches applied
 *  - scheduled patches by group and age
 * @param orgId The organization to which the policy belongs.
 * @param query The url query param used for filtering, sorting, and other such functionality.
 * @returns The overview report for the organization.
 * */
export function getOverviewResults(
  params?: GetOverviewQueryParams,
): Promise<OverviewResults> {
  const queryParams: string = stringifyUrlQuery({
    internal: 1,
    ...params,
  });

  return httpClient
    .get(`/${OVERVIEW_ENDPOINT}?${queryParams}`)
    .then(({ data }) => data);
}
