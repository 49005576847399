var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ax-table',{attrs:{"data-test-id":"reports-overview__table","column-select":false,"headers":_vm.headerConfig,"item-key":"id","items":_vm.tableData},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{
        name: 'devices',
        query: {
          group: item.id,
        },
      }}},[_vm._v(" "+_vm._s(item.total)+" ")])]}},{key:"header.pendingUpdate",fn:function(ref){
      var header = ref.header;
return [_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(header.text))]),_c('ax-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('span',_vm._g({staticClass:"ax-text-teal",attrs:{"role":"button","tabindex":"0"}},on),[_c('v-icon',{attrs:{"aria-hidden":"false","aria-label":_vm.$t('reports.overview.table.pending:tooltip'),"small":"","color":"teal"}},[_vm._v(" "+_vm._s(_vm.mdiInformationOutline)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('reports.overview.table.pending:tooltip'))+" ")])])]}},{key:"item.pendingUpdate",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.pendingUpdate)+" ")]}},_vm._l((_vm.timeColumns),function(column){return {key:("header." + column),fn:function(ref){
      var header = ref.header;
return [_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(header.text))]),_c('ax-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('span',_vm._g({staticClass:"ax-text-teal",attrs:{"role":"button","tabindex":"0"}},on),[_c('v-icon',{attrs:{"aria-hidden":"false","aria-label":_vm.$t('reports.overview.table.remainingPatches:tooltip'),"color":"teal","small":""}},[_vm._v(" "+_vm._s(_vm.mdiInformationOutline)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('reports.overview.table.remainingPatches:tooltip'))+" ")])])]}}}),_vm._l((_vm.timeColumns),function(column){return {key:("item." + column),fn:function(ref){
      var item = ref.item;
return _vm._l((Object.keys(item[column])),function(status){return _c('div',{key:(column + "-" + status),class:[
        ("status-column status-column--" + status),
        { 'ax-text-dark': item[column][status] === 0 } ]},[_c('div',{staticClass:"status-column__label"},[_vm._v(" "+_vm._s(_vm.$t(("general.severities." + status)))+" ")]),(!!item[column][status])?_c('router-link',{staticClass:"status-column__value",attrs:{"to":{
          name: 'devices',
          query: {
            group: item.id,
            severity: status,
          },
        }}},[_vm._v(" "+_vm._s(item[column][status])+" ")]):_c('div',{staticClass:"status-column__value"},[_vm._v(" "+_vm._s(item[column][status])+" ")])],1)})}}})],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }