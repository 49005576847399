export interface PatchSeverityTableData {
  none: number;
  low: number;
  medium: number;
  high: number;
  critical: number;
  unknown: number;
}

export type PatchTimeFrame =
  | 'lessThanFifteenDays'
  | 'fifteenToThirtyDays'
  | 'thirtyOneToSixtyDays'
  | 'sixtyOneToEightyNineDays'
  | 'greaterThanNinetyDays';

export type PatchTableData = {
  [key in PatchTimeFrame]: PatchSeverityTableData;
};

export interface TableData extends PatchTableData {
  id: number;
  name: string;
  pendingUpdate: number;
  total: number;
}

export const EMPTY_PATCH_SEVERITY_TABLE_DATA: PatchSeverityTableData = {
  none: 0,
  low: 0,
  medium: 0,
  high: 0,
  critical: 0,
  unknown: 0,
};
